import {
  Button,
  Checkbox,
  Input,
  InputContainer,
  Label,
  Link,
} from '@cvpartner/design-system';
import React from 'react';
import { useId } from 'react-aria';
import { ClientRouter } from 'components/shared/Link';
import { I18n } from 'util/translations';
import { Form } from './shared/Form';
import {
  CheckboxWrapper,
  LoginButtonWrapper,
  LoginLinkWrapper,
  Wrapper,
} from './shared/Modern';
import { CardContent } from './shared/modern/LoginCard';

export interface Props {
  action?: string;
  allowExtendedLogin?: boolean;
  type: 'normal' | 'external' | 'support';
  alert?: React.ReactNode;
  children?: React.ReactNode;
}

export const KeepMeSignedInCheckbox: React.VFC = () => (
  <CheckboxWrapper>
    <Checkbox name="postpone_expiry_time" size="s">
      {I18n.t('signin.keep_me_signedin_checkbox')}
    </Checkbox>
  </CheckboxWrapper>
);

export const Login: React.VFC<Props> = ({
  action = '/login',
  allowExtendedLogin = true,
  type,
  alert,
  children,
}) => {
  const emailId = useId();
  const passwordId = useId();
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <Wrapper
      heading={I18n.t(
        type === 'external'
          ? 'signin.header_title_external'
          : type === 'support'
            ? 'signin.header_title_admin_support'
            : 'signin.header_title',
      )}
      subheading={
        type === 'support' ? undefined : I18n.t('signin.header_subtitle')
      }
      rebrandInfo={type === 'normal' || type === 'external'}
      alert={alert}
    >
      {children}
      <Form
        action={action}
        onSubmit={() => {
          setIsLoading(true);
        }}
      >
        <CardContent>
          <div>
            <InputContainer>
              <Label htmlFor={emailId}>
                {I18n.t(
                  type === 'external'
                    ? 'signin.personal_email_label'
                    : type === 'support'
                      ? 'signin.label_email'
                      : 'signin.work_email_label',
                )}
              </Label>
              <Input
                id={emailId}
                placeholder={I18n.t('signin.email_placeholder')}
                name="epost"
                type="email"
                required={true}
                autoFocus={true}
              />
            </InputContainer>
          </div>
          <div>
            <InputContainer>
              <Label htmlFor={passwordId}>
                {I18n.t('signin.password_label')}
              </Label>
              <Input
                id={passwordId}
                placeholder={I18n.t('signin.password_placeholder')}
                name="password"
                type="password"
                required={true}
              />
            </InputContainer>
          </div>
          {allowExtendedLogin && <KeepMeSignedInCheckbox />}
          <LoginButtonWrapper center={isLoading}>
            {isLoading ? (
              <Button
                type="submit"
                hierarchy="primary"
                variant="iconOnly"
                loading={true}
              />
            ) : (
              <Button type="submit" hierarchy="primary">
                {I18n.t('signin.signin_button')}
              </Button>
            )}
          </LoginButtonWrapper>
        </CardContent>
      </Form>
      <LoginLinkWrapper>
        <ClientRouter>
          <Link hierarchy="primary" href="/forgotpassword" size="s">
            {I18n.t('signin.forgot_password_link')}
          </Link>
        </ClientRouter>
      </LoginLinkWrapper>
    </Wrapper>
  );
};
