import type React from 'react';
import { Link } from './Link';

export interface Breadcrumb {
  href?: string;
  label: string;
}

interface BreadcrumbProps {
  breadcrumbs_tree: readonly Breadcrumb[];
}

const BreadcrumbLeaf: React.FC<{ breadcrumb: Breadcrumb; isFirst: boolean }> = (
  props,
) => {
  const label = props.isFirst
    ? props.breadcrumb.label
    : ` ${props.breadcrumb.label}`;

  if (props.breadcrumb.href) {
    return (
      <span>
        <Link to={props.breadcrumb.href}>{label} &gt;</Link>
      </span>
    );
  }
  return <span>{label}</span>;
};

export const Breadcrumbs: React.FC<BreadcrumbProps> = (props) => (
  <div className="breadcrumbs_container">
    <div className="breadcrumbs">
      {props.breadcrumbs_tree.map((breadcrumbs_leaf, index) => (
        <BreadcrumbLeaf
          key={index}
          breadcrumb={breadcrumbs_leaf}
          isFirst={index === 0}
        />
      ))}
      {props.children}
    </div>
  </div>
);
