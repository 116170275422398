// AUTO-CONVERTED FROM SPROCKETS
// Original file: app/assets/javascripts/shared/stores/countries_store.js
import Reflux from "reflux";
import _ from "underscore";
import { useSyncExternalStore } from "use-sync-external-store/shim";
import { CountriesActions } from "actions/actions";
import { http } from "util/fetch";
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Removed namespace declaration
export const Countries = Reflux.createStore({
  init() {
    this.listenToMany(CountriesActions);
    this.countries = {};
  },

  getInitialState() {
    return this.countries;
  },

  async reload() {
    const url = "/api/v1/countries/";
    const countries = await http.get(url);
    return this.add_to_cache("all", countries);
  },

  async reload_allowed() {
    const url = "/api/v1/countries/?only_allowed=true";
    const countries = await http.get(url);
    return this.add_to_cache("allowed", countries);
  },

  async update_template(country_id, template) {
    const json = {
      country: {
        cv_template_id: template ? template._id : null,
        cv_template_type: template ? template.template_type : null,
      },
    };

    const url = `/api/v1/countries/${country_id}`;
    const updated_country = await http.put(url, { body: json });
    return this.replace_cache_for_all_keys(updated_country);
  },

  async update_office_template(country_id, office_id, template) {
    const json = {
      office: {
        cv_template_id: template ? template._id : null,
        cv_template_type: template ? template.template_type : null,
      },
    };
    const url = `/api/v1/countries/${country_id}/offices/${office_id}`;
    const updated_office = await http.put(url, { body: json });
    const new_country_id = updated_office.country_id;
    return this.replace_office_cache_for_all_keys(
      new_country_id,
      updated_office,
    );
  },

  async update_office(old_country_id, office_id, updated_office) {
    const json = { office: {} };

    const name = updated_office["name"];
    if (name) {
      json["office"]["name"] = name;
    }
    const country_id = updated_office["country_id"];
    if (country_id && old_country_id !== country_id) {
      json["office"]["country_id"] = country_id;
    }

    const url = `/api/v1/countries/${old_country_id}/offices/${office_id}`;
    updated_office = await http.put(url, { body: json });
    const new_country_id = updated_office.country_id;
    if (old_country_id === new_country_id) {
      return this.replace_office_cache_for_all_keys(
        new_country_id,
        updated_office,
      );
    } else {
      this.remove_office_for_all_keys(old_country_id, office_id);
      return this.add_office_for_all_keys(new_country_id, updated_office);
    }
  },

  async create_office(new_office) {
    const country_id = new_office["country_id"];
    const json = { name: new_office["name"] };
    const url = `/api/v1/countries/${country_id}/offices`;
    const office = await http.post(url, { body: json });
    return this.add_office_for_all_keys(country_id, office);
  },

  async delete_office(country_id, office_id) {
    const url = `/api/v1/countries/${country_id}/offices/${office_id}`;
    await http.delete(url);
    return this.remove_office_for_all_keys(country_id, office_id);
  },

  async create_country(new_country) {
    const json = { country: new_country };
    const url = `/api/v1/countries`;
    await http.post(url, { body: json });
    return this.reload();
  },

  async update_country(country_id, updated_country) {
    const json = { country: updated_country };
    const url = `/api/v1/countries/${country_id}`;
    await http.put(url, { body: json });
    return this.reload();
  },

  // TODO: this endpoint does not exist yet
  async delete_country(country_id) {
    const url = `/api/v1/countries/${country_id}`;
    await http.delete(url);
    return this.reload();
  },

  replace_cache_for_all_keys(country) {
    return _.each(_.keys(this.countries), (key) => {
      return this.replace_cache_for(key, country);
    });
  },

  replace_office_cache_for_all_keys(country_id, updated_office) {
    return _.each(_.keys(this.countries), (key) => {
      return this.replace_office_cache_for(key, country_id, updated_office);
    });
  },

  add_office_for_all_keys(country_id, new_office) {
    return _.each(_.keys(this.countries), (key) => {
      return this.add_to_office_cache_for(key, country_id, new_office);
    });
  },

  remove_office_for_all_keys(country_id, office_id) {
    return _.each(_.keys(this.countries), (key) => {
      return this.remove_from_office_cache_for(key, country_id, office_id);
    });
  },

  replace_cache_for(key, updated_country) {
    const new_countries = (this.countries[key] || []).map((country) => {
      if (updated_country._id === country._id) {
        return updated_country;
      }
      return country;
    });
    return this.add_to_cache(key, new_countries);
  },

  replace_office_cache_for(key, country_id, updated_office) {
    const new_countries = (this.countries[key] || []).map((country) => {
      if (country_id === country._id) {
        return this.replace_office_for_country(country, updated_office);
      }
      return country;
    });
    return this.add_to_cache(key, new_countries);
  },

  add_to_office_cache_for(key, country_id, new_office) {
    const new_countries = (this.countries[key] || []).map((country) => {
      if (country_id === country._id) {
        return this.add_office_for_country(country, new_office);
      }
      return country;
    });
    return this.add_to_cache(key, new_countries);
  },

  remove_from_office_cache_for(key, country_id, office_id) {
    const new_countries = (this.countries[key] || []).map((country) => {
      if (country_id === country._id) {
        return this.remove_office_for_country(country, office_id);
      }
      return country;
    });
    return this.add_to_cache(key, new_countries);
  },

  replace_office_for_country(country, updated_office) {
    const new_offices = (country.offices || []).map((office) => {
      if (updated_office._id === office._id) {
        return updated_office;
      }
      return office;
    });
    country["offices"] = new_offices;
    return country;
  },

  add_office_for_country(country, new_office) {
    const new_offices = country.offices || [];
    new_offices.push(new_office);
    country.offices = new_offices;
    return country;
  },

  remove_office_for_country(country, office_id) {
    const new_offices = _.reject(
      country.offices || [],
      (office) => office._id === office_id,
    );
    country.offices = new_offices;
    return country;
  },

  add_to_cache(key, countries) {
    this.countries[key] = countries;
    return this.trigger(this.countries);
  },
});

// we need to return something that is `===` with the previous value, or
// `useSyncExternalStore` ends up in stack overflow
const emptyArray = [];

function subscribeAllowed(callback) {
  return Countries.listen((countries) => {
    callback(countries.allowed || emptyArray);
  });
}

function getSnapshotAllowed() {
  return Countries.countries.allowed || emptyArray;
}

export function useAllowedCountries() {
  return useSyncExternalStore(subscribeAllowed, getSnapshotAllowed);
}

function subscribeAll(callback) {
  return Countries.listen((countries) => {
    callback(countries.all || emptyArray);
  });
}

function getSnapshotAll() {
  return Countries.countries.all || emptyArray;
}

export function useAllCountries() {
  return useSyncExternalStore(subscribeAll, getSnapshotAll);
}
