// AUTO-CONVERTED FROM SPROCKETS
// Original file: app/assets/javascripts/shared/lib/country_and_office_id_selector.js
import _ from "underscore";

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export class CountryAndOfficeIdSelector {
  constructor(options) {
    if (options == null) {
      options = {};
    }
    this.countries = options.countries;
  }

  new_selected_country_id() {
    const first_country = _.first(this.countries);
    if (!first_country) {
      return false;
    }
    return first_country._id;
  }

  new_selected_office_id() {
    const first_country = _.first(this.countries);
    if (!first_country) {
      return false;
    }
    const first_office = _.first(first_country.offices);
    if (!first_office) {
      return false;
    }
    return first_office._id;
  }
}
