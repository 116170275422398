import { Alert } from '@cvpartner/design-system';
import { ArrowRight } from '@untitled-ui/icons-react';
import type React from 'react';
import { I18n } from 'util/translations';
import {
  PasswordChange,
  type ChangeProps as PasswordChangeProps,
} from './PasswordChange';

type Props = Omit<PasswordChangeProps, 'type' | 'alert'>;

export const ChangePasswordConfirmation: React.VFC<Props> = (props) => (
  <PasswordChange
    type="change"
    {...props}
    alert={
      <Alert
        type="success"
        title={I18n.t('changing_password.alert.success')}
        actions={[
          {
            link: '/dashboard',
            displayText: I18n.t(
              'changing_password.alert.success_link_go_to_company_overview',
            ),
            trailingIcon: ArrowRight,
          },
        ]}
      />
    }
  />
);
