import { Button } from '@cvpartner/design-system';
import { ArrowLeft } from '@untitled-ui/icons-react';
import type React from 'react';
import { I18n } from 'util/translations';
import { LoginButtonWrapper, Wrapper } from './shared/Modern';

export const Troubleshoot: React.VFC = () => (
  <Wrapper
    heading={I18n.t('troubleshoot.header_title_trouble')}
    subheading={I18n.t('troubleshoot.header_subtitle_trouble').split('\n\n')}
  >
    <LoginButtonWrapper center={true}>
      <Button variant="link" href="/login" leadingIcon={ArrowLeft}>
        {I18n.t('forgot_password.link_back_to_signin')}
      </Button>
    </LoginButtonWrapper>
  </Wrapper>
);
