import { Button, Link } from '@cvpartner/design-system';
import { ArrowLeft } from '@untitled-ui/icons-react';
import type * as React from 'react';
import { I18n } from 'util/translations';
import { ClientRouter } from '../../shared/Link';
import { LoginButtonWrapper, LoginLinkWrapper, Wrapper } from './shared/Modern';

export const ResetPasswordReceipt: React.VFC = () => (
  <Wrapper
    heading={I18n.t('forgot_password.header_title_receipt')}
    subheading={I18n.t('forgot_password.header_subtitle_receipt_instructions')}
  >
    <LoginLinkWrapper>
      <Link hierarchy="primary" href="/login" size="s" leadingIcon={ArrowLeft}>
        {I18n.t('forgot_password.link_back_to_signin')}
      </Link>
    </LoginLinkWrapper>
    <LoginButtonWrapper center={true}>
      <ClientRouter>
        <Button variant="link" hierarchy="tertiary" href="/login/troubleshoot">
          {I18n.t('signin.alert.signin_failed_bt_troubleshoot')}
        </Button>
      </ClientRouter>
    </LoginButtonWrapper>
  </Wrapper>
);
