import { Button, ComboBox, ComboBoxItem } from "@cvpartner/design-system";
import { reaction } from "mobx";
import * as React from "react";
import { Form, FormValidationContext } from "react-aria-components";
import _ from "underscore";
import { CountryAndOfficeIdSelector as LibCountryAndOfficeIdSelector } from "auto-converted/lib/CountryAndOfficeIdSelector";
import { name_for_legacy as name_for_legacy_country_code } from "auto-converted/lib/CountryCodeUtils";
import { Countries as CountriesStore } from "auto-converted/stores/Countries";
import { Wrapper } from "components/verticals/password/shared/Modern";
import { CardContent } from "components/verticals/password/shared/modern/LoginCard";
import type { Country } from "schema/shared";
import { CurrentUser } from "stores/CurrentUser";
import {
  type VoidFunctions,
  subscribeAndPopulateWithInitialData,
} from "stores/legacy-util";
import { I18n } from "util/translations";

const FormButton: React.VFC<{ handleSave: () => void }> = ({ handleSave }) => {
  const validationErrors = React.useContext(FormValidationContext);

  return (
    <Button
      onPress={handleSave}
      disabled={Object.keys(validationErrors).length > 0}
    >
      {I18n.t("signin.continue_button")}
    </Button>
  );
};

type ComponentProps = Record<string, never>;

interface ComponentState {
  countries: readonly Country[];
  selected_country_id: false | string;
  selected_office_id: false | string;
  showing_error_message: boolean;
}

export class Show extends React.PureComponent<ComponentProps, ComponentState> {
  unsubscribers: VoidFunctions = [];

  state: ComponentState = {
    countries: [],
    selected_country_id: false,
    selected_office_id: false,
    showing_error_message: false,
  };

  componentDidMount() {
    this.unsubscribers = [
      reaction(
        () => [CurrentUser.country_id, CurrentUser.office_id],
        this.current_user_changed,
      ),
      subscribeAndPopulateWithInitialData(
        CountriesStore,
        this.countries_changed,
      ),
    ];
  }

  componentWillUnmount() {
    for (const unsubscriber of this.unsubscribers) {
      unsubscriber();
    }
    this.unsubscribers = [];
  }

  current_user_changed = ([country_id, office_id]: (string | undefined)[]) => {
    const country_and_office_id_selector = new LibCountryAndOfficeIdSelector({
      countries: this.state.countries,
    });

    this.setState({
      selected_country_id:
        country_id || country_and_office_id_selector.new_selected_country_id(),
      selected_office_id:
        office_id || country_and_office_id_selector.new_selected_office_id(),
    });
  };

  countries_changed = (countries) => {
    const new_countries = countries["all"] || [];
    const new_state_hash = {};

    if (!_.isEqual(this.state.countries, new_countries)) {
      new_state_hash["countries"] = new_countries;
    }

    const country_and_office_id_selector = new LibCountryAndOfficeIdSelector({
      countries: new_countries,
    });
    if (!this.state.selected_country_id) {
      new_state_hash["selected_country_id"] =
        country_and_office_id_selector.new_selected_country_id();
    }
    if (!this.state.selected_office_id) {
      new_state_hash["selected_office_id"] =
        country_and_office_id_selector.new_selected_office_id();
    }

    if (Object.keys(new_state_hash).length > 0) {
      this.setState(new_state_hash);
    }
  };

  handle_save = () => {
    const { selected_office_id, selected_country_id } = this.state;
    if (!selected_office_id || !selected_country_id) {
      return;
    }
    const update_hash = {
      country_id: selected_country_id,
      office_id: selected_office_id,
    };
    return CurrentUser.update_country_and_office(update_hash, "/home");
  };

  render() {
    if (!this.state.selected_country_id) {
      return (
        <Wrapper
          heading={I18n.t("signin.header_title")}
          subheading={I18n.t("signin.header_subtitle_SSO")}
        >
          {null}
        </Wrapper>
      );
    }

    const officesForCountry =
      this.state.countries.find(
        (country) => country.id === this.state.selected_country_id,
      )?.offices ?? [];

    return (
      <Wrapper
        heading={I18n.t("signin.header_title")}
        subheading={I18n.t("signin.header_subtitle_SSO")}
      >
        <Form
          validationErrors={
            officesForCountry.length > 0
              ? undefined
              : { department_picker: I18n.t("no_departments_added_to_country") }
          }
        >
          <CardContent>
            <ComboBox
              isRequired={true}
              onSelectionChange={(country_id) => {
                if (
                  typeof country_id !== "string" ||
                  country_id === this.state.selected_country_id
                ) {
                  return;
                }

                const firstOffice =
                  this.state.countries.find(
                    (country) => country.id === country_id,
                  )?.offices[0]?.id ?? false;

                this.setState({
                  selected_country_id: country_id,
                  selected_office_id: firstOffice,
                });
              }}
              selectedKey={this.state.selected_country_id}
              label={I18n.t("signin.country_label")}
              defaultItems={this.state.countries}
            >
              {(country) => (
                <ComboBoxItem>
                  {name_for_legacy_country_code(country.code)}
                </ComboBoxItem>
              )}
            </ComboBox>
            <ComboBox
              name="department_picker"
              isRequired={true}
              onSelectionChange={(office_id) => {
                if (typeof office_id === "string") {
                  this.setState({ selected_office_id: office_id });
                }
              }}
              selectedKey={this.state.selected_office_id || null}
              label={I18n.t("signin.department_label")}
              defaultItems={officesForCountry}
            >
              {(office) => <ComboBoxItem>{office.name!}</ComboBoxItem>}
            </ComboBox>
            <FormButton handleSave={this.handle_save} />
          </CardContent>
        </Form>
      </Wrapper>
    );
  }
}
