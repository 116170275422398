import { Link } from '@cvpartner/design-system';
import { ArrowLeft } from '@untitled-ui/icons-react';
import type React from 'react';
import { I18n } from 'util/translations';
import { LoginLinkWrapper, Wrapper } from './shared/Modern';

export const Logout: React.VFC = () => (
  <Wrapper
    heading={I18n.t('signedout.header_title')}
    subheading={I18n.t('signedout.header_subtitle')}
  >
    <LoginLinkWrapper>
      <Link href="/login" leadingIcon={ArrowLeft} size="s">
        {I18n.t('forgot_password.link_back_to_signin')}
      </Link>
    </LoginLinkWrapper>
  </Wrapper>
);
