import { Button } from '@cvpartner/design-system';
import * as React from 'react';
import { I18n } from 'util/translations';
import { Form } from './shared/Form';
import { LoginButtonWrapper, Wrapper } from './shared/Modern';

export const ForceResetPassword: React.VFC = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <Wrapper
      heading={I18n.t('resetting_password.header_title_forced')}
      subheading={I18n.t('resetting_password.header_subtitle_forced').split(
        '\n\n',
      )}
    >
      <Form
        action="/force_reset_password"
        onSubmit={() => {
          setIsLoading(true);
        }}
      >
        <LoginButtonWrapper center={isLoading}>
          {isLoading ? (
            <Button
              type="submit"
              hierarchy="primary"
              variant="iconOnly"
              loading={true}
            />
          ) : (
            <Button type="submit" hierarchy="primary">
              {I18n.t('resetting_password.button_send')}
            </Button>
          )}
        </LoginButtonWrapper>
      </Form>
    </Wrapper>
  );
};
