import { I18n } from "util/translations";

export function name_for_legacy(country_code: string | null) {
  return country_code
    ? I18n.t(to_iso_3166_1(country_code).toUpperCase(), {
        scope: "countries",
      })
    : "";
}

export function to_iso_3166_1(country_code: string) {
  if (country_code === "uk") {
    return "gb";
  }
  if (country_code === "sr") {
    return "rs";
  }
  return country_code;
}
