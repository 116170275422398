import { Button, Input, InputContainer, Label } from '@cvpartner/design-system';
import * as React from 'react';
import { useId } from 'react-aria';
import { I18n } from 'util/translations';
import { Form } from './shared/Form';
import { LoginButtonWrapper, Wrapper } from './shared/Modern';
import { CardContent } from './shared/modern/LoginCard';

export const OtpLogin: React.VFC = () => {
  const otpId = useId();
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <Wrapper
      heading={I18n.t('signin.header_title')}
      subheading={I18n.t('signin.header_subtitle')}
    >
      <Form
        action="/login/otp"
        onSubmit={() => {
          setIsLoading(true);
        }}
      >
        <CardContent>
          <div>
            <InputContainer>
              <Label htmlFor={otpId}>{I18n.t('signin.otp_label')}</Label>
              <Input
                id={otpId}
                placeholder="123456"
                name="otp_code"
                type="number"
                required={true}
                autoFocus={true}
              />
            </InputContainer>
          </div>
          <LoginButtonWrapper center={isLoading}>
            {isLoading ? (
              <Button
                type="submit"
                hierarchy="primary"
                variant="iconOnly"
                loading={true}
              />
            ) : (
              <Button type="submit" hierarchy="primary">
                {I18n.t('signin.continue_button')}
              </Button>
            )}
          </LoginButtonWrapper>
        </CardContent>
      </Form>
    </Wrapper>
  );
};
