import { Alert, Button } from '@cvpartner/design-system';
import type React from 'react';
import { I18n } from 'util/translations';
import { KeepMeSignedInCheckbox } from './Login';
import { Form } from './shared/Form';
import { LoginButtonWrapper, Wrapper } from './shared/Modern';
import { CardContent } from './shared/modern/LoginCard';

export const TimeoutSSO: React.VFC = () => (
  <Wrapper
    heading={I18n.t('signin.header_title')}
    alert={
      <Alert type="warning" title={I18n.t('signin.alert.signout_inactivity')} />
    }
  >
    <Form action="/login/timeout_sso">
      <CardContent>
        <KeepMeSignedInCheckbox />
        <LoginButtonWrapper center={false}>
          <Button type="submit" hierarchy="primary">
            {I18n.t('signin.signin_button')}
          </Button>
        </LoginButtonWrapper>
      </CardContent>
    </Form>
  </Wrapper>
);
