import { Alert } from '@cvpartner/design-system';
import type React from 'react';
import { I18n } from 'util/translations';
import { Wrapper } from './shared/Modern';

export const UnblockLoginError: React.VFC = () => (
  <Wrapper
    heading={I18n.t('unblocked.title_account_blocked')}
    subheading={I18n.t('signin.header.subtitle_cantact_manager')}
    alert={
      <Alert
        type="error"
        title={I18n.t('signin.alert.error')}
        description={I18n.t('signin.alert.error_msg_expired')}
      />
    }
  />
);
