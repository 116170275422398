import { Card } from '@cvpartner/design-system';
import type * as React from 'react';
import {
  card,
  cardContent,
  cardHeading,
  cardSubheading,
  cardSubheadingMultiple,
  cardWrapper,
  headingWrapper,
} from './LoginCard.css';

const Subheading: React.VFC<{
  subheading: string | readonly string[] | undefined;
}> = ({ subheading }) => {
  if (!subheading) {
    return null;
  }

  if (typeof subheading === 'string') {
    return <h3 className={cardSubheading}>{subheading}</h3>;
  }

  return (
    <>
      {subheading.map((paragraph, index) => (
        <p key={index} className={cardSubheadingMultiple}>
          {paragraph}
        </p>
      ))}
    </>
  );
};

export const LoginCard: React.VFC<{
  heading: string;
  subheading?: string | readonly string[];
  hasHeaderElement: boolean;
  children: React.ReactNode;
}> = ({ heading, subheading, hasHeaderElement, children }) => {
  const element = (
    <Card>
      <div className={card}>
        <div className={headingWrapper}>
          <h2 className={cardHeading}>{heading}</h2>
          <Subheading subheading={subheading} />
        </div>
        {children}
      </div>
    </Card>
  );

  if (!hasHeaderElement) {
    return element;
  }

  return <div className={cardWrapper}>{element}</div>;
};

export const CardContent: React.VFC<{
  children: React.ReactNode;
}> = ({ children }) => <div className={cardContent}>{children}</div>;
