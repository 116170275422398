import type * as React from 'react';
import { getCsrfToken } from 'util/fetch';

export const Form: React.VFC<{
  action: string;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  children: React.ReactNode;
}> = ({ action, onSubmit, children }) => (
  <form action={action} method="POST" onSubmit={onSubmit}>
    {children}
    <input
      type="hidden"
      name="authenticity_token"
      value={getCsrfToken()}
      autoComplete="off"
    />
  </form>
);
