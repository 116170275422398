import {
  Button,
  FieldError,
  Input,
  Label,
  TextField,
} from '@cvpartner/design-system';
import { Type } from '@sinclair/typebox';
import { TypeCompiler } from '@sinclair/typebox/compiler';
import { CheckCircle, XCircle } from '@untitled-ui/icons-react';
import React from 'react';
import { Form } from 'react-aria-components';
import { httpChecked } from 'util/fetch';
import { I18n } from 'util/translations';
import {
  requirementIcon,
  requirementText,
  requirementWrapper,
  requirementsWrapper,
} from './PasswordChange.css';
import { LoginButtonWrapper, Wrapper } from './shared/Modern';
import { CardContent } from './shared/modern/LoginCard';

const PasswordResponse = Type.Union([
  Type.Object({
    password_error: Type.String(),
    new_path: Type.Optional(Type.Never()),
  }),
  Type.Object({
    password_error: Type.Optional(Type.Never()),
    new_path: Type.String(),
  }),
]);

const PasswordResponseChecker = TypeCompiler.Compile(PasswordResponse);

interface BaseProps {
  initial_password_error: string | undefined;
  user_name: string;
  user_birthday: string;
  submit_path: string;
  user_id?: string;
  alert?: React.ReactNode;
}

export interface ChangeProps extends BaseProps {
  type: 'change';
  display: {
    title: string;
    old_password: string;
    new_password: string;
    submit: string;
  };
}

export interface ResetProps extends BaseProps {
  type: 'reset';
  reset_password_code: string;
  display: {
    title: string;
    new_password: string;
    submit: string;
  };
}

export type Props = ChangeProps | ResetProps;

interface pcState {
  password_error: string;
  old_password: string;
  new_password: string;
  passwordIsDictionary?: boolean;
  passwordIsRepetitive?: boolean;
  passwordIsContextual?: boolean;
  passwordIsShort?: boolean;
  is_loading: boolean;
}

type RequirementState = 'neutral' | 'valid' | 'invalid';

function passwordRequirementToState(
  value: boolean | undefined,
): RequirementState {
  switch (value) {
    case true:
      return 'invalid';
    case false:
      return 'valid';
    case undefined:
      return 'neutral';
  }
}

const PasswordRequirement: React.VFC<{
  state: RequirementState;
  label: string;
}> = ({ state, label }) => (
  <div className={requirementWrapper}>
    {state === 'neutral' ? (
      <CheckCircle className={requirementIcon({ state })} />
    ) : state === 'valid' ? (
      <CheckCircle className={requirementIcon({ state })} />
    ) : (
      <XCircle className={requirementIcon({ state })} />
    )}
    <div className={requirementText}>{I18n.t(label)}</div>
  </div>
);

export class PasswordChange extends React.PureComponent<Props, pcState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      password_error: props.initial_password_error || '',
      old_password: '',
      new_password: '',
      passwordIsDictionary: undefined,
      passwordIsRepetitive: undefined,
      passwordIsContextual: undefined,
      passwordIsShort: undefined,
      is_loading: false,
    };
  }

  handle_old_password_changed: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    return this.setState({ old_password: e.target.value, password_error: '' });
  };

  handle_new_password_changed: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    this.run_passwordyness_checks(e.target.value);
    return this.setState({ new_password: e.target.value });
  };

  handle_submit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    this.setState({ is_loading: true });

    e.preventDefault();
    e.stopPropagation();

    const data = new FormData();
    if (this.props.type === 'change') {
      data.append('old_password', this.state.old_password);
    }
    data.append('new_password', this.state.new_password);
    if (this.props.type === 'reset') {
      data.append('reset_password_code', this.props.reset_password_code);
    }
    data.append('user_id', this.props.user_id || '');

    try {
      const json = await httpChecked.post(
        this.props.submit_path,
        PasswordResponseChecker,
        { body: data },
      );
      this.setState({
        password_error: json.password_error || '',
        is_loading: false,
      });
      if (json.new_path) {
        window.location.href = json.new_path;
      }
    } catch (error) {
      this.setState({ is_loading: false });

      throw error;
    }
  };

  run_passwordyness_checks = (candidate: string) => {
    if (!candidate) {
      this.setState({
        passwordIsDictionary: undefined,
        passwordIsRepetitive: undefined,
        passwordIsContextual: undefined,
        passwordIsShort: undefined,
      });
    } else {
      this.setState({
        passwordIsDictionary: this.is_password_dictionary(candidate),
        passwordIsRepetitive: this.is_password_repetitive(candidate),
        passwordIsContextual: this.is_password_contextual(candidate),
        passwordIsShort: this.is_password_short(candidate),
      });
    }
  };

  is_password_dictionary = (candidate: string) => {
    return candidate.toLowerCase() === 'dictionary'; // will be replaced later with actual dictionary check
  };

  is_password_repetitive = (candidate: string) => {
    const l = Math.floor(candidate.length / 2);
    return candidate.includes(candidate.substring(0, l), l);
  };

  is_password_contextual = (candidate: string) => {
    if (candidate.toLowerCase().includes(this.props.user_name.toLowerCase())) {
      return true;
    }
    if (candidate.includes(this.props.user_birthday)) {
      return true;
    }
    const birth_year = this.props.user_birthday.substring(0, 4);
    if (candidate.includes(birth_year)) {
      return true;
    }

    return false;
  };

  is_password_short = (candidate: string) => {
    return candidate.length < 8;
  };

  render() {
    return (
      <Wrapper
        heading={I18n.t(
          this.props.type === 'reset'
            ? 'resetting_password.header_title'
            : 'changing_password.header_title',
        )}
        subheading={I18n.t('resetting_password.header_subtitle')}
        alert={this.props.alert}
        rebrandInfo={this.props.type === 'reset'}
        breadcrumbs={
          this.props.type === 'change'
            ? [{ label: I18n.t('changing_password.header_title') }]
            : undefined
        }
      >
        <Form
          onSubmit={this.handle_submit}
          validationBehavior="aria"
          validationErrors={
            this.state.password_error
              ? this.props.type === 'reset'
                ? { new_password: this.state.password_error }
                : { old_password: this.state.password_error }
              : undefined
          }
        >
          <CardContent>
            {this.props.type === 'change' && (
              <TextField name="old_password" type="password">
                <Label>
                  {I18n.t('changing_password.label_current_password')}
                </Label>
                <Input
                  placeholder={I18n.t('signin.password_placeholder')}
                  onChange={this.handle_old_password_changed}
                  required={true}
                />
                <FieldError />
              </TextField>
            )}
            <TextField name="new_password" type="password">
              <Label>{I18n.t('resetting_password.label_new_password')}</Label>
              <Input
                placeholder={I18n.t('signin.password_placeholder')}
                onChange={this.handle_new_password_changed}
                required={true}
              />
              <FieldError />
            </TextField>
            <div className={requirementsWrapper}>
              <PasswordRequirement
                state={passwordRequirementToState(this.state.passwordIsShort)}
                label="resetting_password.body_minimum_characters"
              />
              <PasswordRequirement
                state={passwordRequirementToState(
                  this.state.passwordIsContextual,
                )}
                label="resetting_password.body_avoid_user_info"
              />
              <PasswordRequirement
                state={passwordRequirementToState(
                  this.state.passwordIsRepetitive,
                )}
                label="resetting_password.body_avoid_repetition"
              />
            </div>
            <LoginButtonWrapper center={this.state.is_loading}>
              {this.state.is_loading ? (
                <Button
                  type="submit"
                  hierarchy="primary"
                  variant="iconOnly"
                  loading={true}
                />
              ) : (
                <Button
                  type="submit"
                  hierarchy="primary"
                  disabled={
                    this.state.passwordIsContextual ||
                    this.state.passwordIsRepetitive ||
                    this.state.passwordIsShort ||
                    (this.props.type === 'change'
                      ? !this.state.old_password || !this.state.new_password
                      : !this.state.new_password)
                  }
                >
                  {I18n.t('resetting_password.bt_save_new_password')}
                </Button>
              )}
            </LoginButtonWrapper>
          </CardContent>
        </Form>
      </Wrapper>
    );
  }
}
