import { Button } from '@cvpartner/design-system';
import { ArrowLeft, File06, SearchLg } from '@untitled-ui/icons-react';
import { observer } from 'mobx-react';
import type * as React from 'react';
import { ClientRouter } from 'components/shared/Link';
import { CurrentUser } from 'stores/CurrentUser';
import { I18n } from 'util/translations';
import {
  buttons,
  container,
  header,
  headingAndSupportingText,
  icon,
  paragraph,
  wrapper,
} from './404.css';

const isDashboard = window.location.pathname === '/dashboard';

const LinkOrAnchor: React.VFC<{
  to: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  children: string;
}> = ({ to, icon, children }) => {
  const button = (
    <div>
      <Button
        variant="link"
        href={isDashboard ? to : `/dashboard#${to}`}
        leadingIcon={icon}
      >
        {children}
      </Button>
    </div>
  );

  if (isDashboard) {
    return <ClientRouter>{button}</ClientRouter>;
  }

  return button;
};

const baseCvUrl = '/persons/proposal/false';

const LinksComponent: React.VFC = () => {
  const { default_cv_id, id } = CurrentUser;

  const cvUrl =
    default_cv_id && id ? `${baseCvUrl}/cv/${id}/${default_cv_id}` : baseCvUrl;

  return (
    <>
      <GoBack />
      <LinkOrAnchor to="/dashboard" icon={SearchLg}>
        {I18n.t('application_navigation.company_overview')}
      </LinkOrAnchor>
      <LinkOrAnchor to={cvUrl} icon={File06}>
        {I18n.t('My_CV')}
      </LinkOrAnchor>
    </>
  );
};

const Links = observer(LinksComponent);

const GoBack: React.VFC = () => {
  // Nowhere to go back
  if (window.history.length < 2) {
    return null;
  }

  return (
    <div>
      <Button
        hierarchy="secondaryColor"
        leadingIcon={ArrowLeft}
        onPress={() => {
          window.history.back();
        }}
      >
        {I18n.t('go_back')}
      </Button>
    </div>
  );
};

const Icon: React.VFC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="514"
    height="164"
    fill="none"
    className={icon}
  >
    <circle cx="101" cy="22" r="20" stroke="currentColor" strokeWidth="2" />
    <circle cx="101" cy="142" r="20" stroke="currentColor" strokeWidth="2" />
    <circle cx="21" cy="102" r="20" stroke="currentColor" strokeWidth="2" />
    <circle cx="141" cy="102" r="20" stroke="currentColor" strokeWidth="2" />
    <circle cx="193" cy="82" r="20" stroke="currentColor" strokeWidth="2" />
    <circle cx="313" cy="82" r="20" stroke="currentColor" strokeWidth="2" />
    <circle cx="253" cy="22" r="20" stroke="currentColor" strokeWidth="2" />
    <circle cx="253" cy="142" r="20" stroke="currentColor" strokeWidth="2" />
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M1 102c0-11.046 8.954-20 20-20h120c11.046 0 20 8.954 20 20s-8.954 20-20 20H21c-11.046 0-20-8.954-20-20Z"
    />
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M101 162c-11.046 0-20-8.954-20-20V22c0-11.046 8.954-20 20-20s20 8.954 20 20v120c0 11.046-8.954 20-20 20Z"
    />
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M7.142 115.995c-7.81-7.811-7.81-20.474 0-28.284L86.711 8.142c7.81-7.81 20.473-7.81 28.284 0 7.81 7.81 7.81 20.474 0 28.284l-79.569 79.569c-7.81 7.81-20.473 7.81-28.284 0Z"
    />
    <circle cx="453" cy="22" r="20" stroke="currentColor" strokeWidth="2" />
    <circle cx="453" cy="142" r="20" stroke="currentColor" strokeWidth="2" />
    <circle cx="373" cy="102" r="20" stroke="currentColor" strokeWidth="2" />
    <circle cx="493" cy="102" r="20" stroke="currentColor" strokeWidth="2" />
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M353 102c0-11.046 8.954-20 20-20h120c11.046 0 20 8.954 20 20s-8.954 20-20 20H373c-11.046 0-20-8.954-20-20Z"
    />
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M453 162c-11.046 0-20-8.954-20-20V22c0-11.046 8.954-20 20-20s20 8.954 20 20v120c0 11.046-8.954 20-20 20Z"
    />
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M359.142 115.995c-7.81-7.811-7.81-20.474 0-28.284l79.569-79.569c7.81-7.81 20.473-7.81 28.284 0 7.81 7.81 7.81 20.474 0 28.284l-79.569 79.569c-7.81 7.81-20.473 7.81-28.284 0Z"
    />
    <circle cx="253" cy="82" r="80" stroke="currentColor" strokeWidth="2" />
    <circle cx="253" cy="82" r="40" stroke="currentColor" strokeWidth="2" />
    <path stroke="currentColor" strokeWidth="2" d="M0 1h513M0 163h513" />
  </svg>
);

export const NotFound: React.VFC<{ isLoggedIn: boolean }> = ({
  isLoggedIn,
}) => (
  <main className={wrapper}>
    <div className={container}>
      <div>
        <div className={headingAndSupportingText}>
          <h1 className={header}>{I18n.t('under_maintenance')}</h1>
          <p className={paragraph}>{I18n.t('page_moved')}</p>
        </div>
        <div className={buttons}>
          {isLoggedIn ? (
            <Links />
          ) : (
            <>
              <GoBack />
              <div>
                <Button variant="link" href="/login">
                  {I18n.t('signin.signin_button')}
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      <div>
        <Icon />
      </div>
    </div>
  </main>
);
