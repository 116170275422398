import $ from 'jquery';

document.addEventListener('DOMContentLoaded', () => {
  let location = window.location;

  $('.system_errormessage').show();

  $('.system_errormessage .close').click(() => {
    $('.system_errormessage').removeClass('error');
  });

  $('.system_errormessage .reload').click(() => {
    location.reload();
  });

  $('.system_updatemessage .reload').click(() => {
    location.reload();
  });
});
