import { Alert } from '@cvpartner/design-system';
import type * as React from 'react';
import { I18n } from 'util/translations';
import {
  PasswordChange,
  type ResetProps as PasswordChangeProps,
} from './PasswordChange';

type Props = Omit<PasswordChangeProps, 'type' | 'alert'>;

export const ResetPasswordError: React.VFC<Props> = (props) => (
  <PasswordChange
    type="reset"
    {...props}
    alert={
      <Alert
        type="error"
        title={I18n.t('resetting_password.alert.error')}
        description={I18n.t('resetting_password.alert.error_msg_try_again')}
      />
    }
  />
);
