import * as Sentry from '@sentry/react';
import { action } from 'mobx';
import type { Store } from 'reflux';
import _ from 'underscore';

interface RefluxWithDefaultValue<T> {
  // context argument is never used, but we wanna keep the same interface as
  // Reflux's listen method
  listen(callback: (data: T) => void, context?: unknown): VoidFunction;
}

// This function makes sure to give each listener the latest data available in
// the store. This avoids issues where MobX optimizes away an update to the
// store, and the listener doesn't get called.
export function makeListenable<T = any>(
  store: Store,
  getDefaultValue: () => T,
): RefluxWithDefaultValue<T> {
  return {
    listen(callback: (data: T) => void) {
      // wrap the callback in an action so that the callbacks are allowed to read and write reactive states
      const actionCallback = action('reflux-state-callback', callback);

      setTimeout(() => {
        const defaultValue = getDefaultValue();

        actionCallback(defaultValue);
      }, 0);

      const unsubscribe = store.listen(actionCallback, null);

      return () => {
        unsubscribe();
      };
    },
  };
}

interface StoreWithInitialState<T> extends Store {
  getInitialState?(): T;
}

function defaultChecker<T>(value: T): boolean {
  return !_.isEmpty(value);
}

type VoidFunction = () => void;

export type VoidFunctions = VoidFunction[];

export function subscribeAndPopulateWithInitialData<T = any>(
  store: StoreWithInitialState<T>,
  callback: (data: T) => void,
  shouldUpdate: typeof defaultChecker | boolean = defaultChecker,
): VoidFunction {
  // wrap the callback in an action so that the callbacks are allowed to read and write reactive states
  const actionCallback = action('reflux-state-callback', callback);

  const subscription = store.listen(actionCallback, null) as VoidFunction;

  if (typeof store.getInitialState !== 'function') {
    Sentry.captureException(
      new Error('Store does not have getInitialState method'),
    );
    return subscription;
  }

  if (typeof shouldUpdate === 'function') {
    const defaultValue = store.getInitialState();

    if (shouldUpdate(defaultValue)) {
      actionCallback(defaultValue);
    }
  } else {
    if (shouldUpdate) {
      actionCallback(store.getInitialState());
    }
  }

  return subscription;
}
