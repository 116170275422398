import rebrandText from '@cvpartner/design-system/logos/mainWordmark.svg';
import rebrandLogo from '@cvpartner/design-system/logos/royalBlueIcon.svg';
import type * as React from 'react';
import { I18n } from 'util/translations';
import { logo, logoContainer, logoImage, logoSubtext } from './Logo.css';

export const Logo: React.VFC = () => (
  <div className={logoContainer} aria-hidden={true}>
    <div className={logo}>
      <img src={rebrandLogo} className={logoImage} />
      <img src={rebrandText} className={logoImage} />
    </div>
    <div className={logoSubtext}>{I18n.t('signin.logo_tagline')}</div>
  </div>
);
