import { Button, ComboBox, ComboBoxItem } from '@cvpartner/design-system';
import { User01 } from '@untitled-ui/icons-react';
import * as React from 'react';
import { Form } from './shared/Form';
import { Wrapper } from './shared/Modern';
import { CardContent } from './shared/modern/LoginCard';

interface User {
  id: string;
  name: string | null;
  email: string;
}

export const LoginAdminAs: React.VFC<{ users: readonly User[] }> = ({
  users,
}) => {
  const [selectedUser, setSelectedUser] = React.useState<User>();
  const [inputText, setInputText] = React.useState<string>();

  const filteredUsers = React.useMemo(() => {
    if (!inputText || inputText === (selectedUser?.name ?? selectedUser?.id)) {
      return users;
    }

    const lowerCaseInputValue = inputText.toLowerCase();

    return users.filter((user) => {
      if (user.name?.toLowerCase().includes(lowerCaseInputValue)) {
        return true;
      }

      if (user.email?.toLowerCase().includes(lowerCaseInputValue)) {
        return true;
      }

      if (user.id === lowerCaseInputValue) {
        return true;
      }

      return false;
    });
  }, [inputText, selectedUser, users]);

  return (
    <Wrapper heading="Sign in as">
      {users.length === 0 ? (
        <CardContent>
          No international managers found. Is the subdomain correct?
        </CardContent>
      ) : (
        <Form action="/login/as">
          <CardContent>
            <ComboBox
              name="user_id"
              label="Pick a user (international managers only)"
              description="You can filter by name, email or ID"
              items={filteredUsers}
              onSelectionChange={(key) => {
                if (key) {
                  const user = users.find((user) => user.id === key);
                  setSelectedUser(user);
                } else {
                  setSelectedUser(undefined);
                }
              }}
              onInputChange={setInputText}
            >
              {(user) => (
                <ComboBoxItem textValue={user.name || user.id} icon={User01}>
                  {user.name || user.id}
                </ComboBoxItem>
              )}
            </ComboBox>
            {selectedUser ? (
              <>
                <div>ID: {selectedUser.id}</div>
                <div>Email: {selectedUser.email}</div>
              </>
            ) : null}
            <Button
              hierarchy="primary"
              type="submit"
              disabled={selectedUser == null}
            >
              Sign in as selected user
            </Button>
          </CardContent>
        </Form>
      )}
    </Wrapper>
  );
};
