import {
  Button,
  FieldError,
  Input,
  Label,
  Link,
  TextField,
} from '@cvpartner/design-system';
import { ArrowLeft } from '@untitled-ui/icons-react';
import * as React from 'react';
import { I18n } from 'util/translations';
import { Form } from './shared/Form';
import { LoginButtonWrapper, LoginLinkWrapper, Wrapper } from './shared/Modern';
import { CardContent } from './shared/modern/LoginCard';

export const ForgotPassword: React.VFC = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');

  return (
    <Wrapper
      heading={I18n.t('forgot_password.header_title')}
      subheading={I18n.t('forgot_password.header_subtitle_instructions')}
      rebrandInfo={true}
    >
      <Form
        action="/forgotpassword"
        onSubmit={() => {
          setIsLoading(true);
        }}
      >
        <CardContent>
          <TextField name="email" type="email">
            <Label>{I18n.t('signin.label_email')}</Label>
            <Input
              required={true}
              autoFocus={true}
              placeholder={I18n.t('signin.email_placeholder')}
              onChange={(e) => setEmail(e.target.value)}
            />
            <FieldError />
          </TextField>
          <LoginButtonWrapper center={isLoading}>
            {isLoading ? (
              <Button
                type="submit"
                hierarchy="primary"
                variant="iconOnly"
                loading={true}
              />
            ) : (
              <Button type="submit" hierarchy="primary" disabled={!email}>
                {I18n.t('forgot_password.button_send')}
              </Button>
            )}
          </LoginButtonWrapper>
        </CardContent>
      </Form>
      <LoginLinkWrapper>
        <Link
          hierarchy="primary"
          href="/login"
          size="s"
          leadingIcon={ArrowLeft}
        >
          {I18n.t('forgot_password.link_back_to_signin')}
        </Link>
      </LoginLinkWrapper>
    </Wrapper>
  );
};
