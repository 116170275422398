import { Alert } from '@cvpartner/design-system';
import * as Sentry from '@sentry/react';
import * as React from 'react';
import { I18n } from 'util/translations';

export function useTranslatedErrorMessage(
  message: string | undefined,
  params?: Record<string, unknown>,
) {
  const errorMessage = message
    ? I18n.t(`errors.login.${message}`, {
        ...params,
        defaultValue: '',
      })
    : undefined;

  React.useEffect(() => {
    if (!message) {
      return;
    }

    // since we provide a default value, the error logging won't kick in
    if (!errorMessage) {
      Sentry.withScope((scope) => {
        scope.setLevel('warning');
        scope.setExtra('scope', 'errors.login');
        scope.captureException(new Error(`Missing translation: "${message}"`));
      });
    }
  }, [errorMessage, message]);

  return errorMessage || message;
}

export const TranslateErrorMessage: React.VFC<{
  message: string | undefined;
  params?: Record<string, unknown>;
}> = ({ message, params }) => {
  const errorMessage = useTranslatedErrorMessage(message, params);

  if (!errorMessage) {
    return null;
  }

  return <Alert title={errorMessage} type="error" />;
};
