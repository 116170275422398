import { Alert, Link } from '@cvpartner/design-system';
import { Mail01 } from '@untitled-ui/icons-react';
import type React from 'react';
import { I18n } from 'util/translations';
import { Login, type Props as LoginProps } from './Login';
import { LoginLinkWrapper, Wrapper } from './shared/Modern';
import {
  TranslateErrorMessage,
  useTranslatedErrorMessage,
} from './shared/TranslateErrorMessage';

interface Props
  extends Pick<LoginProps, 'type' | 'allowExtendedLogin' | 'action'> {
  message: string;
  errParams: Record<string, string>;
  supportEmail: string;
}

export const LoginError: React.VFC<Props> = ({
  message,
  errParams,
  supportEmail,
  type,
  allowExtendedLogin,
  action,
}) => {
  const defaultSupportEmail = 'support@flowcase.com';
  const errorMessage = useTranslatedErrorMessage(message, errParams);

  if (!message) {
    return (
      <Login
        alert={<Alert type="error" title={I18n.t('signin.alert.error')} />}
        type={type}
        allowExtendedLogin={allowExtendedLogin}
        action={action}
      />
    );
  }

  if (message !== 'user_not_external' && message !== 'user_deactivated') {
    return (
      <Login
        alert={
          errorMessage ? (
            <TranslateErrorMessage message={message} params={errParams} />
          ) : (
            <Alert
              type="error"
              title={I18n.t('signin.alert.error')}
              description={I18n.t('signin.alert.signin_failed_msg_invalid')}
            />
          )
        }
        type={type}
        allowExtendedLogin={allowExtendedLogin}
        action={action}
      />
    );
  }

  if (message === 'user_deactivated') {
    return (
      <Wrapper
        heading={I18n.t('signin.header.title_account_deactivated')}
        subheading={I18n.t('signin.contact_manager')}
        alert={
          <Alert
            type="error"
            title={I18n.t('signin.header.title_account_deactivated')}
            description={I18n.t('signin.alert.error_msg_deactivated')}
          />
        }
      >
        {supportEmail !== defaultSupportEmail && (
          <LoginLinkWrapper>
            <Link leadingIcon={Mail01} href={`mailto:${supportEmail}`}>
              {I18n.t('signin.contact_manager')}
            </Link>
          </LoginLinkWrapper>
        )}
      </Wrapper>
    );
  }

  // Unknown condition for the error - I'm assuming it's going to be when there's an error
  // that we haven't captured yet
  return (
    <Login
      alert={<TranslateErrorMessage message={message} params={errParams} />}
      allowExtendedLogin={allowExtendedLogin}
      type={type}
      action={action}
    />
  );
};
