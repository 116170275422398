import { Alert } from '@cvpartner/design-system';
import type React from 'react';
import { I18n } from 'util/translations';
import {
  PasswordChange,
  type ChangeProps as PasswordChangeProps,
} from './PasswordChange';

type Props = Omit<PasswordChangeProps, 'type' | 'alert'> & {
  message: string | undefined;
};

export const ChangePasswordError: React.VFC<Props> = (props) => (
  <PasswordChange
    {...props}
    type="change"
    alert={
      <Alert
        type="error"
        title={I18n.t('resetting_password.alert.error')}
        description={I18n.t('resetting_password.alert.error_msg_try_again')}
      />
    }
  />
);
