import { CountriesActions } from "actions/actions";
import { Show as ViewsSelectOfficeShow } from "auto-converted/views/selectoffice/Show";
import { ChangePasswordConfirmation } from "components/verticals/password/ChangePasswordConfirmation";
import { ChangePasswordError } from "components/verticals/password/ChangePasswordError";
import { ExternalLogin } from "components/verticals/password/ExernalLogin";
import { ForceResetPassword } from "components/verticals/password/ForceReset";
import { ForgotPassword } from "components/verticals/password/ForgotPassword";
import { Login } from "components/verticals/password/Login";
import { LoginAdminAs } from "components/verticals/password/LoginAdminAs";
import { LoginError } from "components/verticals/password/LoginError";
import { Timeout } from "components/verticals/password/LoginTimeout";
import { TimeoutSSO } from "components/verticals/password/LoginTimeoutSso";
import { Logout } from "components/verticals/password/Logout";
import { OauthFailure } from "components/verticals/password/OauthFailure";
import { OtpLogin } from "components/verticals/password/Otp";
import { PasswordChange } from "components/verticals/password/PasswordChange";
import { ResetPasswordError } from "components/verticals/password/ResetPasswordError";
import { ResetPasswordReceipt } from "components/verticals/password/ResetPasswordReceipt";
import { SelectDomain } from "components/verticals/password/SelectDomain";
import { Troubleshoot } from "components/verticals/password/Troubleshoot";
import { UnblockLoginError } from "components/verticals/password/UnblockLoginError";
import { UnblockLoginReceipt } from "components/verticals/password/UnblockLoginReceipt";
import { UserAuthenticatedButDoesNotExistError } from "components/verticals/password/UserAuthenticatedButDoesNotExistError";
import { CurrentUser } from "stores/CurrentUser";
import type { CVPartnerReactRoute, Options } from "types";
import { renderRouter } from "util/react";
import { NotFound } from "views/errors/404";

export class LoginRouter implements CVPartnerReactRoute {
  data: Options["data"];

  constructor(options: Options) {
    this.data = options.data;
  }

  render() {
    return renderRouter(
      [
        {
          children: [
            {
              path: "login",
              children: [
                {
                  index: true,
                  element: <Login type="normal" />,
                },
                {
                  path: "admin",
                  children: [
                    {
                      index: true,
                      element: (
                        <Login
                          action="/login/admin"
                          allowExtendedLogin={false}
                          type="support"
                        />
                      ),
                    },
                    {
                      path: "error",
                      element: (
                        <LoginError
                          message={this.data?.message}
                          errParams={this.data?.err_params}
                          supportEmail={this.data?.support_email}
                          action="/login/admin"
                          allowExtendedLogin={false}
                          type="support"
                        />
                      ),
                    },
                  ],
                },
                {
                  path: "otp",
                  element: <OtpLogin />,
                },
                {
                  path: "as",
                  element: <LoginAdminAs users={this.data?.users} />,
                },
                {
                  path: "external",
                  children: [
                    {
                      index: true,
                      element: <ExternalLogin />,
                    },
                    {
                      path: "error",
                      element: (
                        <LoginError
                          message={this.data?.message}
                          errParams={this.data?.err_params}
                          supportEmail={this.data?.support_email}
                          action="/login/external"
                          type="external"
                        />
                      ),
                    },
                  ],
                },
                {
                  path: "troubleshoot",
                  element: <Troubleshoot />,
                },
                {
                  path: "timeout",
                  element: <Timeout />,
                },
                {
                  path: "timeout_sso",
                  element: <TimeoutSSO />,
                },
                {
                  path: "error",
                  element: (
                    <LoginError
                      message={this.data?.message}
                      errParams={this.data?.err_params}
                      supportEmail={this.data?.support_email}
                      type="normal"
                    />
                  ),
                },
                {
                  path: "user_authenticated_but_does_not_exist_error",
                  element: (
                    <UserAuthenticatedButDoesNotExistError
                      federationGroupMembers={
                        this.data?.federation_group_members
                      }
                      field={this.data?.field}
                      user_id={this.data?.user_id}
                      supportEmail={this.data?.support_email}
                    />
                  ),
                },
                {
                  path: "select/domain",
                  element: (
                    <SelectDomain
                      googleAppsDomains={this.data?.google_apps_domains}
                    />
                  ),
                },
              ],
            },
            {
              path: "resetpassword",
              children: [
                {
                  path: "receipt",
                  element: <ResetPasswordReceipt />,
                },
                {
                  path: "error",
                  element: (
                    <ResetPasswordError
                      initial_password_error={this.data?.password_error}
                      user_name={this.data?.user_name}
                      user_birthday={this.data?.user_birthday}
                      submit_path={`/resetpassword/${this.data?.user_id}`}
                      reset_password_code={this.data?.reset_password_code}
                      user_id={this.data?.user_id}
                      display={{
                        title: "reset_password",
                        new_password: "enter_new_password",
                        submit: "save_new_password",
                      }}
                    />
                  ),
                },
                {
                  path: ":user_id",
                  element: (
                    <PasswordChange
                      type="reset"
                      initial_password_error={this.data?.password_error}
                      user_name={this.data?.user_name}
                      user_birthday={this.data?.user_birthday}
                      submit_path={`/resetpassword/${this.data?.user_id}`}
                      reset_password_code={this.data?.reset_password_code}
                      user_id={this.data?.user_id}
                      display={{
                        title: "reset_password",
                        new_password: "enter_new_password",
                        submit: "save_new_password",
                      }}
                    />
                  ),
                },
              ],
            },
            {
              path: "change_password",
              children: [
                {
                  index: true,
                  element: (
                    <PasswordChange
                      type="change"
                      initial_password_error={this.data?.password_error}
                      user_name={this.data?.user_name}
                      user_birthday={this.data?.user_birthday}
                      submit_path="/change_password"
                      user_id={this.data?.user_id}
                      display={{
                        title: "change_password",
                        old_password: "current_password",
                        new_password: "new_password",
                        submit: "change_password",
                      }}
                    />
                  ),
                },
                {
                  path: "confirmation",
                  element: (
                    <ChangePasswordConfirmation
                      initial_password_error={undefined}
                      user_name={this.data?.user_name}
                      user_birthday={this.data?.user_birthday}
                      submit_path="/change_password"
                      user_id={this.data?.user_id}
                      display={{
                        title: "change_password",
                        old_password: "current_password",
                        new_password: "new_password",
                        submit: "change_password",
                      }}
                    />
                  ),
                },
                {
                  path: "error",
                  element: (
                    <ChangePasswordError
                      message={this.data?.message}
                      initial_password_error={this.data?.password_error}
                      user_name={this.data?.user_name}
                      user_birthday={this.data?.user_birthday}
                      submit_path="/change_password"
                      user_id={this.data?.user_id}
                      display={{
                        title: "change_password",
                        old_password: "current_password",
                        new_password: "new_password",
                        submit: "change_password",
                      }}
                    />
                  ),
                },
              ],
            },
            {
              path: "unblock",
              children: [
                {
                  path: "receipt",
                  element: <UnblockLoginReceipt />,
                },
                {
                  path: "error",
                  element: <UnblockLoginError />,
                },
              ],
            },
            {
              path: "forgotpassword",
              element: <ForgotPassword />,
            },
            {
              path: "force_reset_password",
              element: <ForceResetPassword />,
            },
            {
              path: "select/office",
              element: <ViewsSelectOfficeShow />,
              loader: async () => {
                await Promise.all([
                  CurrentUser.reload(),
                  CountriesActions.reload(),
                ]);

                return null;
              },
            },
            {
              path: "logout",
              element: <Logout />,
            },
            {
              path: "auth/failure",
              element: (
                <OauthFailure
                  message={this.data?.message}
                  errParams={this.data?.err_params}
                />
              ),
            },
            {
              path: "*",
              element: (
                <NotFound isLoggedIn={window.cvpartner?.isLoggedIn ?? false} />
              ),
            },
          ],
          loader: async ({ request }) => {
            const url = new URL(request.url);

            if (
              url.pathname !== "/login" &&
              url.pathname !== "/login/admin" &&
              url.pathname !== "/login/otp" &&
              url.pathname !== "/logout" &&
              window.cvpartner?.isLoggedIn
            ) {
              await CurrentUser.reload();
            }

            return null;
          },
        },
      ],
      "container",
      true,
    );
  }
}
