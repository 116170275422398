import { Alert } from '@cvpartner/design-system';
import type React from 'react';
import { I18n } from 'util/translations';
import { Login } from './Login';

export const Timeout: React.VFC = () => (
  <Login
    alert={
      <Alert type="warning" title={I18n.t('signin.alert.signout_inactivity')} />
    }
    type="normal"
  />
);
